import * as React from "react"
import styled from "styled-components"
import WhyUsSection from "./WhyUsSection";
import {observer} from "mobx-react-lite";
import WhoAreWeSection from "./WhoAreWe";
import HelpYouSection from "./HelpYouSection";
import translation from "../../shared/utils/translation";
import TitlePrimary from "../../shared/components/text/TitlePrimary";
import DividerLine from "../../shared/components/DividerLine";
import {sectionMargin} from "../../shared/utils/vars";
import ImageGrid from "../../shared/components/grid/ImageGrid";
import useWindowDimensions from "../../shared/utils/windowSize";
import {sizes} from "../../shared/utils/media";

const Wrapper = styled.div`
  overflow: hidden;
  margin-top: 48px;
`
const TitleWrapper = styled.div`
  text-align: center;
  margin: ${sectionMargin}
`

const HomeWrapper = () => {

  const { width } = useWindowDimensions()

  let ImageCollage = [
    {src: "/static/images/adapter.png"},
    {src: "/static/images/powersupply.png"},
  ]

  return (
    <Wrapper>
      <TitleWrapper>
        <TitlePrimary small={width < sizes.tablet} >{translation("common.title.home")}</TitlePrimary>
      </TitleWrapper>
      <ImageGrid object={[{src: "static/images/red-banner.jpg"}]} imageStyle={{maxWidth: "100%", height: "100"}}/>
      <DividerLine/>
      <WhyUsSection/>
      <DividerLine/>
      <ImageGrid object={ImageCollage} imageStyle={{maxWidth: "100%", height: "360px"}}/>
      <DividerLine/>
      <WhoAreWeSection/>
      <DividerLine/>
      <HelpYouSection/>
    </Wrapper>
  )
}

export default observer(HomeWrapper)