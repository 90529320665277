import React, {useCallback, useEffect, useRef, useState} from "react"
import styled from "styled-components"
import Logo from "./Logo";
import {observer} from "mobx-react-lite";
import translation from "../utils/translation";
import {sizes} from "../utils/media";
import useWindowDimensions from "../utils/windowSize";
import {Link} from "react-router-dom";
import LanguageSelect from "./LanguageSelect";
import {menuIsOpen, selectedLanguage, setMenuIsOpen} from "../stores/ConfigStore";
import OutlineMenuIcon from "./icons/OutlineMenuIcon";

const HeaderWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 80px);
  flex-shrink: 0;
  height: ${p => p.isScrolled ? "42px" : "48px"};
  background-color: ${p => p.theme.headerBgColor};
  color: ${p => p.theme.headerTextColor};
  padding: 20px 40px;
  z-index: 998;
  @media only screen and (max-width:${sizes.tablet}px){
    height: 40px;
    padding: 12px 25px;
    width: calc(100% - 50px);
  }
`

const HeaderText = styled.span`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 15px;
  padding: 12px 20px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border: ${p => p.active ? "1px solid white" : "none"};
  :hover{
    color: rgb(255,255,255, .6);
  }
`

const NavList = styled.div`
  display: flex;
  flex-direction: row;
`

const Right = styled.div`
  display: flex;
  align-content: center;
  position: absolute;
  right: 0;
`

const Header = () => {

  const { width } = useWindowDimensions();

  const [isScrolled, setIsScrolled] = useState(false);
  const ref = useRef(null)
  const [open, setOpen] = useState(false)

  const clickOrEscapeListener = useCallback((e) => {
    const isEscapeEvent = e instanceof KeyboardEvent && e.key === "Escape"
    const isOutsideClick =
      e instanceof MouseEvent &&
      ref.current &&
      !ref.current.contains(e.target)
    if (isEscapeEvent || isOutsideClick) {
      onClose()
    }
  }, [])

  useEffect(() => {
    document.addEventListener("click", clickOrEscapeListener)
    document.addEventListener("keyup", clickOrEscapeListener)
    return () => {
      document.removeEventListener("click", clickOrEscapeListener)
      document.removeEventListener("keyup", clickOrEscapeListener)
    }
  }, [clickOrEscapeListener])

  useEffect(() => {
    const handler = () => {
      setIsScrolled((isScrolled) => {
        if (
          !isScrolled &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          return true;
        }

        if (
          isScrolled &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }

        return isScrolled;
      });
    };

    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  const onClose = () => {
    setOpen(false)
  }

 const headerNav = () => {
    
    return(
      <NavList>
        <HeaderText><Link to={"/"}>{translation("common.start")}</Link></HeaderText>
        <HeaderText><a href={selectedLanguage.get() == "en" ? "https://www.toplednordic.com/" : "https://www.toplednordic.se/"} target="_blank">{translation("common.lighting")}</a></HeaderText>
        <HeaderText><a href={"https://shop.toplednordic.com/"} target="_blank">{translation("common.productsAndShop")}</a></HeaderText>
        <HeaderText><Link to={"/aboutus"}>{translation("common.aboutus")}</Link></HeaderText>
      </NavList>
    )
  }

  const openCloseMenu = () => {
    setMenuIsOpen(!menuIsOpen.get())
  }

  return(
    <>
      <HeaderWrapper id="header" isScrolled={isScrolled}>
        <Logo />
        <Right>
        <LanguageSelect/>
          {
            width <= sizes.desktop ?
                !menuIsOpen.get() && <OutlineMenuIcon color={"white"} onClick={openCloseMenu}/>
              :
              headerNav()
          }
        </Right>
      </HeaderWrapper>
    </>

  )
}

export default observer(Header)