import * as React from "react"
import { AiOutlineHome } from "react-icons/ai";
import styled from "styled-components";

const HomeIcon = styled(AiOutlineHome)`
  font-size: ${p => p.small ? 18 : 26}px;
  font-weight: ${p => p.bold ? 900 : 500};
  cursor: pointer;
  margin: ${p => p.margin ? p.margin : 0};
  
  &:hover, &:focus{
    color: lightgray;
  }
`

const OutlineHomeIcon = ({small = false, margin, bold = false, onClick}) => {
  return (
      <HomeIcon small={small} bold={bold} onClick={onClick} margin={margin}/>
  )
}

export default OutlineHomeIcon