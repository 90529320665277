import * as React from "react"
import { AiOutlineShoppingCart } from "react-icons/ai";
import styled from "styled-components";
const ShoppingIcon = styled(AiOutlineShoppingCart)`
  font-size: ${p => p.small ? 18 : 26}px;
  font-weight: ${p => p.bold ? 900 : 500};
  cursor: pointer;
  margin: ${p => p.margin ? p.margin : 0};
  
  &:hover, &:focus{
    color: lightgray;
  }
`

const OutlineShoppingIcon = ({small = false, margin, bold = false, onClick}) => {
  return (
      <ShoppingIcon small={small} bold={bold} margin={margin} onClick={onClick}/>
  )
}

export default OutlineShoppingIcon
