import React from "react"
import styled from "styled-components"
import Menu from "./Menu"

const MenuContainer = styled.div`
  overflow: hidden;
`

const MenuWrapper = ({children}) => {
  return (
    <MenuContainer>
      <Menu/>
    </MenuContainer>
  );
}

export default MenuWrapper;
