import { createGlobalStyle } from "styled-components"
import {formHoverColor} from "../utils/vars"
export default createGlobalStyle`
  html {

  }
  html, body, #__next {
    height: 100%;
    width: 100%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  * {
    padding: 0;
    margin: 0;
    outline: none;
  }
  body {
    font-family: 'Montserrat', sans-serif;
    //add color
    font-weight: 500;
    font-size: 16px;
    background: #F4F4F4;
  }
  p{
    margin: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  input {
    border: none;
    outline: none;
    font-size: 16px;
  }
  h1 {
    font-weight: 800;
    font-size: 50px;
  }
  .ant-row {
    margin: 0;
  }
  .ant-input-textarea > .ant-input-affix-wrapper > textarea{
    font-size: 14px;
    border: 1px solid gray;
    background-color : gainsboro !important;
    padding: 10px 20px;
    height: 200px;
    resize: none;
  }
  .ant-input:hover, .ant-input-textarea > .ant-input-affix-wrapper > textarea:hover{
    background-color: ${formHoverColor} !important;
  }

`