import './App.css';
import React from "react"
import {Routes, Route} from "react-router-dom";
import HomeWrapper from "./pages/home/HomeWrapper";
import Layout from "./shared/components/Layout";
import PageWrapper from "./shared/components/PageWrapper";
import AboutUsWrapper from "./pages/aboutus/AboutUsWrapper";
import config from "./shared/utils/config/config";

class App extends React.Component{
  render(){
    config()

    return (
      <Layout>
        <PageWrapper>
          <Routes>
            <Route exact path="/" element={<HomeWrapper />} />
            <Route path="/aboutus" element={<AboutUsWrapper/>} />
          </Routes >
        </PageWrapper>
      </Layout>
    );
  }
}
//<Route path="/subcategory/:id" element={<SubCategoryWrapper/>} />

export default App;