import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {useCallback, useEffect, useRef, useState} from "react";
import {languages} from "../locales/languages";
import {selectedLanguage, setSelectedLanguage} from "../stores/ConfigStore";
import LinkButton from "./text/LinkButton";
import Flag from "react-flagkit";

const DropdownContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
`
const DropdownListContainer = styled.div`
  position: absolute;
  z-index: 9999;
  ${props => (props.position === "header" ? "bottom: 45px" :"top: 45px"  )};
  width: 100%;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  min-width: 100px;
`
const DropdownList = styled.ul`
  padding: 0;
  margin: 0;
  background: #fff;
  box-sizing: border-box;
  color: #000;
  font-weight: 500;
`
const ListItem = styled.li`
  list-style: none;
  border-bottom: 1px solid #eaeaea;
  text-align: center;
  padding: 10px 5px;
  color: #656565;
  font-weight: bold;
`

const LanguageSelect = () => {
  const ref = useRef(null)
  const [open, setOpen] = useState(false)

  const clickOrEscapeListener = useCallback((e) => {
    const isEscapeEvent = e instanceof KeyboardEvent && e.key === "Escape"
    const isOutsideClick =
      e instanceof MouseEvent &&
      ref.current &&
      !ref.current.contains(e.target)

    if (isEscapeEvent || isOutsideClick) {
      onClose()
    }
  }, [])

  useEffect(() => {
    document.addEventListener("click", clickOrEscapeListener)
    document.addEventListener("keyup", clickOrEscapeListener)
    return () => {
      document.removeEventListener("click", clickOrEscapeListener)
      document.removeEventListener("keyup", clickOrEscapeListener)
    }
  }, [clickOrEscapeListener])

  const onClose = () => {
    setOpen(false)
  }

  const handleLanguageChange = selectedLanguage => {
    setOpen(false)
    setSelectedLanguage(selectedLanguage)
  }

  return (
  <DropdownContainer ref={ref}>
    <LinkButton onClick={() => setOpen(!open)}>
      <Flag country={selectedLanguage.get() === "en" ? "GB" : selectedLanguage.get().toUpperCase()} size={25} />
    </LinkButton>
    {open && (
      <DropdownListContainer>
        <DropdownList>
          {Object.keys(languages).map(lang => (
            <ListItem
              key={lang}
              onClick={() => handleLanguageChange(lang.toLocaleLowerCase())}
              isSelected={selectedLanguage === lang.toLocaleLowerCase()}
            >
              <Flag country={lang === "en" ? "GB" : lang.toUpperCase()} size={25} />
            </ListItem>
          ))}
        </DropdownList>
      </DropdownListContainer>
    )}
  </DropdownContainer>
  )
}

export default observer(LanguageSelect)