import React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import ParagraphPrimary from "../text/ParagraphPrimary";

const TextGridWrapper = styled.div`
  
`

const StrongWordWrapper = styled.div`
  margin: ${(p) => p.margin ? "0" : "15px 0"}
`

const StrongWord = styled.span`
  display: flex;
  font-size: ${(p) => p.size || "32"}px;
  font-weight: 700;
  justify-content: ${(p) => p.justify || "center"};
  align-items: ${(p) => p.align || "center"};
  margin: ${(p) => p.margin || "0"}
`
const TextGrid = ({ object }) => {
  const renderText = (value, index) => {
    const isSmall = value.small;
    const textContent = Array.isArray(value.text) ? value.text.join('\n') : value.text;

    return (
        <div key={index}>
          {value.textAbove && <ParagraphPrimary>{textContent}</ParagraphPrimary>}
          <StrongWordWrapper margin={value.margin}>
            <StrongWord
                small={isSmall}
                size={value.size}
                justify={value.placement}
                align={value.align}
                margin={value.margin}
            >
              {value.strongText && textContent}
            </StrongWord>
          </StrongWordWrapper>
          {value.textBelow && <ParagraphPrimary>{textContent}</ParagraphPrimary>}
        </div>
    );
  };

  return (
      <TextGridWrapper>
        {object.map((value, index) => renderText(value, index))}
      </TextGridWrapper>
  );
};
export default observer(TextGrid)