import React from "react"
import styled from "styled-components"

const PageWrapper = styled.div`
  display: flex;
  margin: auto;
  max-width: 1200px;
`

function Layout({children}) {
  return (
    <PageWrapper>
      {children}
    </PageWrapper>
  );
}

export default Layout;
