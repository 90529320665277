import React from "react"
import styled from "styled-components"

const Text = styled.span`
  font-size: ${p => p.small ? "24px" : "28px"};
  font-weight: 700;
  line-height: 1.25;
  word-wrap: break-word;
  margin: ${p => p.margin ? p.margin : 0};
  color: ${p => p.color ? p.color : "inherit"}
`

const TitleSecondary = ({ children, small = false, margin = false, color = false}) => {
  return(
    <Text
      small={small}
      margin={margin}
      color={color}
    >
      {children}
    </Text>
  )
}

export default TitleSecondary