import React from "react"
import styled from "styled-components"

const Text = styled.p`
    color: rgb(60, 60, 59);
    font-size: ${p => p.small ? "16px" : "32px"};
    line-height: 1.75;
    font-weight: ${p => p.small ? 500 : 700};;
    overflow-wrap: break-word;
    text-align: ${p => p.center ? "center" : "left"};
    ${p => p.style};
`

const ImageText = ({active = false, children, style, center, small = false }) => {
  return(
    <Text
      isActive={active}
      center={center}
      small={small}
      style={style}
    >
      {children}
    </Text>
  )
}

export default ImageText