import * as React from "react"
import { AiOutlineCloseCircle } from "react-icons/ai";
import styled from "styled-components";

const CloseIcon = styled(AiOutlineCloseCircle)`
  font-size: ${p => p.small ? 18 : 26}px;
  font-weight: ${p => p.bold ? 900 : 500};
  cursor: pointer;
  
  &:hover, &:focus{
    color: lightgray;
  }
`

const OutlineCloseIcon = ({small = false, bold = false, onClick}) => {
  return (
      <CloseIcon small={small} bold={bold} onClick={onClick}/>
  )
}

export default OutlineCloseIcon