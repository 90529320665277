import React from "react"
import { ThemeProvider } from "styled-components"
import {gutter} from "../utils/vars";
import {observer} from "mobx-react-lite";

function ThemeContainer(props){

    const gridSpacingConfig = {
        space: [0, gutter, gutter * 1.5, gutter * 2]
    }

    const themeValues ={
        overlayColor: "white",
        headerBgColor: "black",
        headerTextColor: "white"
    }

    return (
     <ThemeProvider theme={{ ...themeValues, ...gridSpacingConfig }}>
       {props.children}
     </ThemeProvider>
    )
}

export default observer(ThemeContainer)