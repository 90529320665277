import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import translation from "../../shared/utils/translation";
import TitlePrimary from "../../shared/components/text/TitlePrimary";
import TextGrid from "../../shared/components/grid/TextGrid";
import {TitleSizes} from "../../shared/utils/vars";
import TitleSecondary from "../../shared/components/text/TitleSecondary";
import ParagraphBold from "../../shared/components/text/ParagraphBold";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  flex-direction: column;
`

const TitleWrapper = styled.div`
  text-align: start;
  margin: 20px 0 10px 0;
`

const StrongWordWrapper = styled.div`
    text-align: start;
    margin: ${p => p.margin ? p.margin : null}
`

const WhyUsText = styled.span`
  text-align: left;
`

const WhyUsSection = () => {

  return (
    <Wrapper>

      <WhyUsText>
        {translation("whyus.paragraph1")}
      </WhyUsText>
      <TitleWrapper>
        <TitleSecondary small={true}>{translation("whyus.paragraph2.title")}</TitleSecondary>
      </TitleWrapper>
      
      <WhyUsText>
        {translation("whyus.paragraph2")}
      </WhyUsText>
      
      <StrongWordWrapper margin={"20px 0"}>
        <ParagraphBold small={true}>{translation("whyus.strongwords")}</ParagraphBold>
      </StrongWordWrapper>
      
      <WhyUsText>
        {translation("whyus.paragraph3")}
      </WhyUsText>
      
      <StrongWordWrapper margin={"20px 0 0 0"}>
        <ParagraphBold small={true}>{translation("whyus.strongwordsquestion")}</ParagraphBold>
      </StrongWordWrapper>
      
    </Wrapper>
  )
}

export default observer(WhyUsSection)