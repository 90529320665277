import React, {useEffect, useState} from "react"
import styled from "styled-components"
import OutlineCloseIcon from "../icons/OutlineCloseIcon";
import {observer} from "mobx-react-lite";
import {menuIsOpen, selectedLanguage, setMenuIsOpen} from "../../stores/ConfigStore";
import translation from "../../utils/translation";
import {useNavigate} from "react-router-dom";
import WhiteButton from "../buttons/WhiteButton";
import {sizes} from "../../utils/media";
import useWindowDimensions from "../../utils/windowSize";
import OutlineHomeIcon from "../icons/OutlineHomeIcon";
import OutlineInfoIcon from "../icons/OutlineInfoIcon";
import OutlineShoppingIcon from "../icons/OutlineShoppingIcon";
import OutlineLightingBulbIcon from "../icons/OutlineLightingbulbIcon";

const MenuContainer = styled.div`
  position: fixed;
  right: 0;
  width: ${p => p.isOpen ? 
          (p.width <= sizes.desktop) ?
            "100%"
                  :
          "360px" : 0};
  height: 100%;
  z-index: 999;
  background-color: white;
  display: flex;
  flex-direction: column;
  transition: width 0.2s ease;
  overflow: hidden;
`

const MenuText = styled.span`
  display: flex;
  align-self: start;
  margin: auto;
  font-size: 24px;
  font-weight: 700;
`

const MenuTextLink = styled.a`
  display: flex;
  align-self: start;
  margin: auto;
  font-size: 24px;
  font-weight: 700;
  text-decoration: none;
  &:focus, &:hover{
    color: black;
    text-decoration: none;
  }
`

const MenuHeader = styled.div`
  display: flex;
  justify-content: end;
  margin: 10px 10px;
`

const MenuContent = styled.div`
  margin: 35px;
`

const Menu = () => {
  const { width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShowingProducts, setIsShowingProducts] = useState(false)
  let navigate = useNavigate();

  useEffect(() => {
    if(menuIsOpen.get() == false){
      setIsShowingProducts(false)
    }
    setIsMenuOpen(menuIsOpen.get())
  }, [menuIsOpen.get()])

  const close = () => {
    setMenuIsOpen(false)
    setIsMenuOpen(false)
  }

  const onMenuItemClick = (path) => {
    close();
    navigate(path)
  }

  const renderMenuContent = () => {
    return(
      <>
        <WhiteButton onClick={() => onMenuItemClick("/")} border={false}>
          <MenuText>
            <OutlineHomeIcon  margin={"auto 10px"}/>{translation("common.home")}
          </MenuText>
        </WhiteButton>
        
        <WhiteButton border={false}>
          <MenuTextLink href={selectedLanguage.get() == "en" ? "https://www.toplednordic.com/" : "https://www.toplednordic.se/"} target="_blank">
            <OutlineLightingBulbIcon margin={"auto 10px"}/>{translation("common.lighting")}
          </MenuTextLink>
        </WhiteButton>
        
        <WhiteButton border={false}>
          <MenuTextLink href={"https://shop.toplednordic.com/"} target="_blank">
            <OutlineShoppingIcon margin={"auto 10px"}/>{translation("common.productsAndShop")}
          </MenuTextLink>
        </WhiteButton>
        <WhiteButton onClick={() => onMenuItemClick("/aboutus")} border={false}>
          <MenuText>
            <OutlineInfoIcon margin={"auto 10px"}/>{translation("common.aboutus")}
          </MenuText>
        </WhiteButton>
      </>
    )
  }

  return (
      <MenuContainer isOpen={isMenuOpen} width={width}>
        <MenuHeader>
          <OutlineCloseIcon bold={true} onClick={() => close()}/>
        </MenuHeader>
        <MenuContent>
          {renderMenuContent()}
        </MenuContent>
      </MenuContainer>
  );
}

export default observer(Menu);
