import { observable, action } from "mobx"
import {setCookieLanguage} from "./CookieStore";

export const hostname = observable.box("")

export const setHostname = action((newHostname) => {
  hostname.set(newHostname)
})

export const defaultLanguage = observable.box("en")

export const setDefaultLanguage = action((lang = "en") => {
  defaultLanguage.set(lang)
})

export const selectedLanguage = observable.box("")

export const setSelectedLanguage = action((lang) => {
  selectedLanguage.set(lang)
  setCookieLanguage(lang)
})

export const menuIsOpen = observable.box("")

export const setMenuIsOpen = action((isOpen) => {
  menuIsOpen.set(isOpen)
})
