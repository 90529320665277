import React from "react"
import styled from "styled-components"

const Text = styled.p`
    font-size: ${p => p.small ? 16 : 18}px;
    line-height: 1.25;
    font-weight: 700;
    overflow-wrap: break-word;
    margin: ${p => p.margin ? p.margin : 0}
`

const ParagraphBold = ({ children, margin = false, small }) => {
  return(
    <Text margin={margin} small={small}>
      {children}
    </Text>
  )
}

export default ParagraphBold