import React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {  Row, Col  } from "antd";
import ImageText from "../text/ImageText";
import translation from "../../utils/translation";
import {useNavigate} from "react-router-dom";
import {selectedLanguage} from "../../stores/ConfigStore";

const GridWrapper = styled.div`
  margin-top: 50px;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: ${p => p.center ? "center" : "inherit"};
`
const TextWrapper = styled.div`
  
`

const ImageGrid = ({object, type, imageStyle, wrapperStyle, itemsPerRow, centerText = false, centerImage = false, smallText = true, textTranslation}) => {
  let navigate = useNavigate();
  
  const renderText = (value) => {
    let style = value.style ? value.style : {}
    return(
      <TextWrapper>
        {
          typeof value.text === "object" ?
            value.text.map((value, index) => {
              return <ImageText key={index} style={style} center={centerText} small={smallText}>{value.includes("@") ? <a href={`mailto: ${value}`}>{value}</a> : value}</ImageText>
            })
            :
            (value.text || value.name) && <ImageText style={style} center={centerText} small={smallText}>{textTranslation ? value.translation ? JSON.parse(value.translation)[selectedLanguage.get()]: translation(textTranslation + (value.name || value.text)) : value.text || value.name}</ImageText>
        }
      </TextWrapper>
    )
  }
  
  const getKeyIndex = (categoryId) => {
    navigate(`/subcategory/${categoryId}`)
  }

  return(
    <GridWrapper>
      {
        itemsPerRow ?
          <Row justify="space-evenly">
            {
              object.map((value, index) => {
                return (
                  <Col key={index} xs={24} sm={24/itemsPerRow * (itemsPerRow/2)} md={24/itemsPerRow} style={wrapperStyle} onClick={type === "category" ? () => getKeyIndex(value.id, index) : null}>
                    {value["textAbove"] && renderText(value)}
                    <ImageWrapper key={index} center={centerImage}>
                      {(value.src || value.image) && <img alt="MissingImage" src={value.src || value.image} style={imageStyle}/>}
                    </ImageWrapper>
                    {!value["textAbove"] && renderText(value)}
                  </Col>
                );
              })
            }
          </Row>
        :
          <Row justify="space-evenly">
            {
              object.map((value, index) => {
                return (
                  <Col key={index} style={wrapperStyle} onClick={type === "category" ? () => getKeyIndex(value.id, index) : null}>
                    {value["textAbove"] && renderText(value)}
                    <ImageWrapper key={index} center={centerImage} >
                      {(value.src || value.image) && <img alt="MissingImage" src={value.src || value.image} style={imageStyle}/>}
                    </ImageWrapper>
                    {!value["textAbove"] && renderText(value)}
                  </Col>
                );
              })
            }
          </Row>
      }

    </GridWrapper>
  )
}

export default observer(ImageGrid)