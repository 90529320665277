import en from "./en.json";
import se from "./se.json";

export const languages = {
  en,
  se
}

export const supportedLanguages = ["en", "se"]

export const fullLanguageName = {
  en: "english",
  se: "swedish"
}
