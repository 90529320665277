import React from "react"
import styled from "styled-components"

const Text = styled.p`
  color: rgb(60, 60, 59);
  font-size: 16px;
  line-height: 1.75;
  font-weight: 500;
  overflow-wrap: break-word;
  text-align: left;
  white-space: pre-line;
`

const ParagraphPrimary = ({children }) => {
  return(
    <Text>
      {children}
    </Text>
  )
}

export default ParagraphPrimary