import React from "react"
import styled from "styled-components"
import TitleSecondary from "../text/TitleSecondary";
import {baseButtonBackgroundColor, baseButtonHoverColor, gutter} from "../../utils/vars";

/*
Figure out better colors
 */

const BaseButtonComponent = styled.button`
  background-color: ${baseButtonBackgroundColor};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  min-width: 150px;
  transition: background 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  padding: ${gutter}px 0;
  width: 100%;
  max-width: 100%;
  margin: 10px auto;

  &:focus,
  &:hover{
    background-color: ${baseButtonHoverColor};
  }
  
`

const BaseButton = ({onClick, text, disabled, loading, children, type = "button", small, props, className}) => {
  const disableClick = disabled || loading;
  const buttonClick = disableClick ? undefined : onClick;

  const renderButtonContent = () => {
    return text ? (
        <TitleSecondary small={small}>
          {text}
        </TitleSecondary>
    ) :(
        <span>{children}</span>
    )
  }

  return (
      <BaseButtonComponent {...props} className={className} type={type} onClick={buttonClick}>
        {renderButtonContent()}
      </BaseButtonComponent>
  )
}

export default BaseButton;
