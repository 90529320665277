import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeContainer from "./shared/components/ThemeContainer";
import GlobalStyle from "./shared/styles/GlobalStyle";
import 'antd/dist/antd.min.css'
import "./web.config";
import {BrowserRouter as Router} from "react-router-dom";
import MenuWrapper from "./shared/components/menu/MenuWrapper";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <MenuWrapper/>
      <ThemeContainer>
        <GlobalStyle/>
        <App />
      </ThemeContainer>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
