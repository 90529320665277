import React from "react"
import {observer} from "mobx-react-lite";
import styled from "styled-components"
import {Col, Row} from "antd";
import {sizes} from "../../shared/utils/media";
import translation from "../../shared/utils/translation";
import Logo from "../../shared/components/Logo";
import DividerLine from "../../shared/components/DividerLine";
import ParagraphBold from "../../shared/components/text/ParagraphBold";

const ContactFormWrapper = styled.div`
  flex-direction: column;
  margin-bottom: 25px;
`
const LogoWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  img{
    
  }
`
const HelpYouSectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width:${sizes.desktop}px){
    justify-content: center;
  }
`


function HelpYouSection(){

  return (
    <HelpYouSectionWrapper>
      <Row type="flex">
        <Col xs={24} md={24} lg={12}>
          <ContactFormWrapper>
          </ContactFormWrapper>
        </Col>
        <Col xs={24} md={24} lg={12}>
          <LogoWrapper>
            <Logo position={"body"} color={"black"}/>
          </LogoWrapper>
          <DividerLine smallGap={true}/>
          <ParagraphBold small={true}>Danavägen 2</ParagraphBold>
          <ParagraphBold small={true}>74652 Bålsta</ParagraphBold>
          <br/>
          <ParagraphBold small={true}><a href={`mailto: info@toplednordic.se`}>info@toplednordic.se</a></ParagraphBold>
          <ParagraphBold small={true}>{translation("phonenumber.office")}</ParagraphBold>
          <ParagraphBold small={true}><a href={"https://www.linkedin.com/company/topled-nordic/"}>LinkedIn</a></ParagraphBold>
        </Col>
      </Row>
    </HelpYouSectionWrapper>
  )
}

export default observer(HelpYouSection)