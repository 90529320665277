export const gutter = 12
export const gridWidth = 1140
export const pageXLarge = 36
export const mobileHeaderHeight = 79
export const desktopHeaderHeight = 78
export const mobileInputRowHeight = 44
export const mobileSwapBarHeight = 150
export const rowHeight = 55
export const sectionMargin = "50px 0"

// fonts
export const mainFont = "'Montserrat', sans-serif;"
export const semi = "600"
export const bold = "bold"
export const extraBold = 800
export const smallerFontSize = "14px"

// colors

export const mainBackground = "#F4F4F4";
export const formHoverColor = "#C8C8C8";
export const buttonHoverColor = "";
export const TitleColor = "#000000"
export const subHeading = "rgb(50, 80, 25)"

export const baseButtonBackgroundColor = "#aac4e2"; //Base color

export const baseButtonHoverColor = "#6f9bce" //Hover base color

export const whiteButtonBackgroundColor = "white";

export const whiteButtonHoverColor = "lightgray"

export const redButtonBackgroundColor = "red"
export const redButtonHoverColor = "darkred"

export const modalBackgroundColor = "white"

export const CustomInputBackgroundColor = "#E8E8E8"

export const CustomInputHoverBackgroundColor = "whitesmoke"

export const ErrorColor = "red";

export const TitleSizes = {
  xs: 10,
  sm: 15,
  md: 20,
  l: 25,
  xl: 30
}