import React from "react"
import styled from "styled-components"
import {Button} from "antd";

const ButtonStyle = styled(Button)`
  display: flex;
  align-items: center;
  background-color: inherit;
  font-size: 14px;
  border: none;
  color: white;
  :hover{
    background-color: inherit;
    color: limegreen;
  }
  :focus{
    background-color: inherit;
    color: limegreen;
  }
  svg{
    margin-right: 5px;
  }
`

const LinkButton = ({ children, icon = false, onClick}) => {
  return(
    <ButtonStyle icon={icon} onClick={onClick}>
      {children}
    </ButtonStyle>
  )
}

export default LinkButton