import * as React from "react"
import styled from "styled-components"

const Divider = styled.hr`
  display: flex;
  flex-direction: row;
  background-color: rgba(0,0,0,0.13);
  border: 0 none;
  color: #eee;
  color: rgba(0,0,0,0.13);
  height: 1px;
  margin: ${p => p.smallGap ? "25px 0" : "50px 0"};
  text-align: center;
  width: 100%;
`

const DividerLine = ({smallGap = false}) => {
  return (
    <Divider smallGap={smallGap}/>
  )
}

export default DividerLine