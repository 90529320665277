import React from "react"
import styled from "styled-components"
import TitleSecondary from "../text/TitleSecondary";
import BaseButton from "./BaseButton";
import {whiteButtonBackgroundColor, whiteButtonHoverColor} from "../../utils/vars";

const PrimaryButtonComponent = styled(BaseButton)`
  background-color: ${whiteButtonBackgroundColor};
  border: ${p => p.border ? "1px solid black" : 0} ;
  &:hover, &:focus {
    background-color: ${whiteButtonHoverColor}
  }
  
`

const WhiteButton = ({onClick, text, disabled, loading, children, border = true, type = "button", small, props}) => {
  const disableClick = disabled || loading;
  const buttonClick = disableClick ? undefined : onClick;

  const renderButtonContent = () => {
    return text ? (
        <TitleSecondary small={small}>
          {text}
        </TitleSecondary>
    ) :(
        <span>{children}</span>
    )
  }

  return (
      <PrimaryButtonComponent {...props} border={border} type={type} onClick={buttonClick}>
        {renderButtonContent()}
      </PrimaryButtonComponent>
  )
}

export default WhiteButton;
