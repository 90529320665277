import * as React from "react"
import { FaRegLightbulb } from "react-icons/fa";
import styled from "styled-components";
const LightingBulbIcon = styled(FaRegLightbulb)`
  font-size: ${p => p.small ? 18 : 26}px;
  font-weight: ${p => p.bold ? 900 : 500};
  cursor: pointer;
  margin: ${p => p.margin ? p.margin : 0};
  
  &:hover, &:focus{
    color: lightgray;
  }
`

const OutlineLightingBulbIcon = ({small = false, margin, bold = false, onClick}) => {
  return (
    <LightingBulbIcon small={small} bold={bold} margin={margin} onClick={onClick}/>
  )
}

export default OutlineLightingBulbIcon
